/* global config, angular */

'use strict';

angular.module('managerApp').controller('LoginController', function (
        $rootScope, $scope, $window, $location, $timeout, $cookies, $mdDialog, Auth, http, toast, users) {

//------------------------------------------------------------------------------

    $('.md-toolbar-tools').hide();
    $scope.loading = true;
    $scope.errors = {};

    $scope.isPartnerSession = config.isPartnerSession;
    $scope.isDevicesManagerSession = config.isDevicesManagerSession;

    $scope.currentUser = Auth.getCurrentUser();

//------------------------------------------------------------------------------

    $scope.login = (form) => {
        if (form.$valid) {
            Auth.login($scope.user.email, $scope.user.password).then(() => {
                $rootScope.loggedIn();

                let next = $scope.isPartnerSession ? '/partner/mon-tableau-de-bord' : '/';
                if ($scope.isDevicesManagerSession === true) {
                    next = '/devices-manager/demandes-paiement-en-especes';
                }
                $timeout(() => {
                    $location.path(next).search('from', 'login');
                });

                if (!$scope.isPartnerSession && !$scope.isDevicesManagerSession) {
                    let tmsPopup = $cookies.get(config.tmsPopup);
                    if (typeof tmsPopup === 'undefined' || (typeof tmsPopup === 'string' && tmsPopup === '1')) {
                        if (Auth.hasPermissions('TMS')) {
                            return showTMSDataPopup();
                        }
                    }
                }

                // to refresh the menu (not needed for a device manager account
                if (!$scope.isDevicesManagerSession) {
                    $timeout(() => {
                        $window.location.reload();
                    }, 100);
                }

            }).catch((err) => {
                $scope.errors.other = err.message
                        ? err.message
                        : 'Une erreur est survenue lors du traitement de votre demande.';
                $timeout(() => {
                    $scope.errors.other = '';
                }, 3500);
                $scope.$apply();
            });
        }
    };

    $scope.setDeviceManagerSession = () => {
        $location.path('/devices-manager/login');
        $window.location.reload();
    };

    function ping() {
        http.get(config.websiteURL + '/ping').then(() => {
            $scope.loading = false;
            $('.md-toolbar-tools').fadeIn();
            console.log('ping : ' + $location.path());
            if ($location.path() !== '/login') {
                $location.path('/').search('from', 'login');
            } else {
                $location.path('/').search({});
            }
        }).catch((err = {}) => {
            if (err.message) {
                toast.show(err.message, 'error', true);
            }//
        });
    }

    function showTMSDataPopup() {
        $mdDialog.show({
            templateUrl: 'app/account/login/tms.data.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {},
            controller: ($scope, $mdDialog, $cookies, toast) => {
                $scope.tms = {
                    noAccount: false
                };
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if ($scope.tms.showPopup === true) { // dont show it again
                        $cookies.put(config.tmsPopup, 0);
                    } else {
                        $cookies.put(config.tmsPopup, 1);
                    }
                    if (confirm) {
                        let user = {
                            tmsLogin: $scope.tms.noAccount ? '' : $scope.tms.login,
                            tmsPassword: $scope.tms.noAccount ? '' : $scope.tms.password
                        };
                        users.update(user, true).then((res) => {
                            toast.show('Les identificant ont bien été enregistrés.', 'success');
                            $window.location.reload();
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : 'Une erreur est survenue lors de traitement de votre demande.', 'error');
                        });
                    } else {
                        $window.location.reload();
                    }//
                };
            }
        }).then(() => {
        }, () => {
        });
    }

//------------------------------------------------------------------------------

    $timeout(() => {
        ping();
    }, 100);

});